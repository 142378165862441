import React from 'react';

const css = `
  .container {
    font-family: 'Inter var', sans-serif;
    position: relative;
    width: 1280px;
    height: 720px;
    box-sizing: border-box;
    background: linear-gradient(135deg, #4ca2cd, 75%, #67b26f);
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .container > :is(img,svg) {
    width: 580px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 0px, rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.2) 0px 20px 30px;
    margin-right: 40px;
  }
  .container > :is(svg, img.noshadow) {
    box-shadow:none;
  }

  .text {
    position: relative;
    width: 550px;
    flex: 0 0 550px;
    max-width:550px;
    color: #fff;
    height: 100%;
    padding: 2rem 2rem 2rem 2rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .text * {
    position: relative;
    z-index: 1;
  }

  .text::before {
    z-index: 0;
    content: ' ';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50rem;
    height: 80rem;
    margin-left: -32rem;
    margin-top: -40rem;
    background: #000;
    transform: rotate(-10deg);
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 0px 0px, rgba(0, 0, 0, 0.3) 0px 0px 8px, rgba(0, 0, 0, 0.2) 20px 0px 30px;
  }

  .text :is(img,svg) {
    margin-top: 2rem;
    height: 3.75rem;
    margin-bottom: 1rem;
    display: inline;
  }

  .text h1 {
    font-size: 3.25rem;
    line-height: 1.1;
    font-weight: 800;
    margin: 0;
    padding-right: 0.5rem;
  }

  .text h2 {
    font-weight: 300;
    font-size: 1.8rem;
    color: #4ca2cd;
    margin: 0;
  }
  .text h3 {
    margin:0;
    font-weight: 800;
    font-size: 2rem;
    color: #67b26f;
  }
  .text h3 b {
    color:#fff;
  }
  `;

export default ({ date, title, prefix, logo, imageType, noImageShadow, image, plural, determiner, bg }) => {
  let string = <>Polypane.app</>;
  let ogTitle = title;

  if (prefix === 'posts') {
    string = (
      <>
        From the <b>Polypane blog</b>
      </>
    );
  } else if (prefix === 'faq') {
    string = (
      <>
        From the <b>Polypane FAQ</b>
      </>
    );
  } else if (prefix === 'terms') {
    string = (
      <>
        Explained in the <b>Polypane Responsive Design Glossary</b>
      </>
    );

    ogTitle = 'What';
    if (plural) {
      ogTitle += ` are ${title}?`;
    } else if (determiner) {
      ogTitle += ` is a ${title}?`;
    } else {
      ogTitle += ` is ${title}?`;
    }
  } else if (prefix === 'docs') {
    string = (
      <>
        From the <b>Polypane docs</b>
      </>
    );
  }

  let style = {};

  if (bg) {
    style = { background: bg };
  }

  const Logo = logo;
  const Image = image;

  return (
    <div className="container" style={style}>
      <div className="text">
        <h1>{ogTitle}</h1>
        {date !== 'Invalid date' ? <h2>{date}</h2> : <></>}

        <Logo />
        <h3>{string}</h3>
      </div>
      <Image
        className={imageType === 'svg' || noImageShadow ? 'noshadow' : ''}
      />
      <style dangerouslySetInnerHTML={{ __html: css }} />
    </div>
  );
};
